import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  userListrecords: [],
  userByIdrecord: null,
  rolesRecords: [],
  departmentRecords: [],
  specializationsRecords: [],
  branchRecords: [],
  currentPage: 1,
  toatalPages: 0,
};

export const UserListSlice = createSlice({
  name: "userlist",
  initialState,
  reducers: {
    getUserListRecords: (state, action) => {
      state.userListrecords = action.payload.data;
      state.toatalPages = action.payload.totalPages;
    },
    getUserById: (state, action) => {
      state.userByIdrecord = action.payload;
    },
    resetuserById: (state, action) => {
      state.userByIdrecord = null;
    },
    getRolesRecords: (state, action) => {
      state.rolesRecords = action.payload;
    },
    getdepartmentRecords: (state, action) => {
      state.departmentRecords = action.payload;
    },
    getspecializationRecords: (state, action) => {
      state.specializationsRecords = action.payload;
    },
    getBranchRecords: (state, action) => {
      state.branchRecords = action.payload;
    },
  },
});

export const {
  getUserListRecords,
  getUserById,
  resetuserById,
  getRolesRecords,
  getdepartmentRecords,
  getspecializationRecords,
  getBranchRecords,
} = UserListSlice.actions;

export default UserListSlice.reducer;
