import { createSlice } from "@reduxjs/toolkit";
import jwtDecode from "jwt-decode";
const initialState = {
  isAuthenticated: false,
  forgotPasswordState: null,
  validateTokenResponseState: null,
  setPasswordState: null,
  loader: false,
  userRole: [],
  userBranches: [],
  userAccess: [],
  defaultBranch: null,
};

const authenticationSlice = createSlice({
  name: "authentication",
  initialState,
  reducers: {
    login(state) {
      state.loader = true;
    },
    loginSuccess(state, action) {
      state.loader = false;
      state.isAuthenticated = !!action.payload.token;
      state.token = JSON.stringify(action.payload.token);
      localStorage.setItem("isAuthenticated", state.isAuthenticated);
      localStorage.setItem("token", state.token);
      localStorage.setItem("token_bearer", action.payload.token);
      localStorage.setItem("token_type", action.payload.token_Type);
      localStorage.setItem("defaultBranch", action.payload.defaultBranch);
      localStorage.setItem("selectedBranch", action.payload.defaultBranch);
      localStorage.setItem(
        "user_Access",
        JSON.stringify(action.payload.userAccess)
      );

      localStorage.setItem(
        "user_Branches",
        JSON.stringify(action.payload.userBranches)
      );

      localStorage.setItem(
        "user_Roles",
        JSON.stringify(action.payload.userRoles)
      );
      const decoded = jwtDecode(action.payload.token);
      if (decoded) {
        state.user = JSON.stringify(decoded);
        localStorage.setItem("user", JSON.stringify(decoded));
        localStorage.setItem("userName", decoded.displayname);
        localStorage.setItem("userId", decoded.employeeid);
      }
      if (action.payload.roles) {
        state.roles = action.payload.roles;
        localStorage.setItem("roles", action.payload.roles);
      }
    },
    loginFailure(state) {
      state.loader = false;
      state.isAuthenticated = false;
    },
    loginSessionAvailable(state) {
      let token = localStorage.getItem("token");
      let token_type = localStorage.getItem("token_type");
      let token_bearer = localStorage.getItem("token_bearer");
      if (token && token_type && token_bearer) {
        const decoded = jwtDecode(token);
        const date = new Date(0);
        date.setUTCSeconds(decoded.exp);
        if (date === undefined || !(date.valueOf() > new Date().valueOf())) {
          state.isAuthenticated = false;
        } else {
          state.isAuthenticated = true;
        }
      } else {
        state.isAuthenticated = false;
      }
    },

    forgotPasswordSuccess(state, action) {
      state.forgotPasswordState = action.payload;
    },
    forgotPasswordFailure(state) {
      state.forgotPasswordState = false;
    },
    validateTokenResponse(state, action) {
      state.validateTokenResponseState = action.payload;
    },
    setPasswordSuccess(state, action) {
      state.setPasswordState = action.payload;
    },
    setDefaultBranch(state, action) {
      state.defaultBranch = action.payload;
    },
  },
});

export const {
  login,
  loginSuccess,
  loginFailure,
  loginSessionAvailable,
  forgotPasswordSuccess,
  forgotPasswordFailure,
  validateTokenResponse,
  setPasswordSuccess,
  setDefaultBranch,
} = authenticationSlice.actions;
export default authenticationSlice.reducer;
