import authentication from "features/OnBoarding/authenticationSlice";
import schedule from "features/Schedule/ScheduleSlice";

import GlobalSpinner from "features/CommonComponents/GlobalSpinner/GlobalSpinnerSlice";
import RoleManagement from "features/Admin/Roles/RolesSlice";
import userlist from "features/Admin/Users/UsersSlice";
import appointments from "features/Appointments/AppointmentsSlice";

//Include all the reducer to combine and provide to configure store.

const rootReducer = {
  authentication,
  schedule,
  GlobalSpinner,
  RoleManagement,
  userlist,
  appointments,
};

export default rootReducer;
