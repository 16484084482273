import { createSlice } from "@reduxjs/toolkit";
import dayjs from "dayjs";

const initialState = {
  doctorListRecords: [],
  appointmentSlots: [],
  rescheduleSuccess: false,
  appointmentCounts: [],
  totalSchedule: 0,
  nurseListRecords: [],
  patientListRecords: [],
  calendarDate: dayjs().startOf("day"),
};

export const ScheduleSlice = createSlice({
  name: "schedule",
  initialState,
  reducers: {
    getDoctorListRecords: (state, action) => {
      state.doctorListRecords = action.payload;
    },
    getappointmentSlots: (state, action) => {
      state.appointmentSlots = action.payload;
    },
    setRescheduleSuccess: (state, action) => {
      state.rescheduleSuccess = action.payload;
    },
    setAppointmentCounts: (state, action) => {
      state.appointmentCounts = action.payload;
    },
    resetAppointmentCounts: (state, action) => {
      state.appointmentCounts = null;
    },
    setTotalSchedule: (state, action) => {
      state.totalSchedule = action.payload;
    },
    getNurseListRecords: (state, action) => {
      state.nurseListRecords = action.payload;
    },
    getPatientRecords: (state, action) => {
      state.patientListRecords = action.payload;
    },
    setCalendarDate(state, action) {
      state.calendarDate = action.payload;
    },
  },
});

export const {
  getDoctorListRecords,
  getappointmentSlots,
  setRescheduleSuccess,
  setAppointmentCounts,
  resetAppointmentCounts,
  setTotalSchedule,
  getNurseListRecords,
  getPatientRecords,
  setCalendarDate,
} = ScheduleSlice.actions;

export default ScheduleSlice.reducer;
