import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  appointmentRecords: [],
  currentPage: 1,
  toatalPages: 0,
  previousAppointmentsRecords: [],
  selectedPatient: {},
};

export const AppointmentsSlice = createSlice({
  name: "appointments",
  initialState,
  reducers: {
    getAppointmentRecords: (state, action) => {
      state.appointmentRecords = action.payload.data;
      state.toatalPages = action.payload.totalPages;
    },
    getPreviousAppointmentsRecords: (state, action) => {
      state.previousAppointmentsRecords = action.payload;
    },
    setSelectedPatient: (state, action) => {
      state.selectedPatient = action.payload;
    },
  },
});
export const {
  getAppointmentRecords,
  getPreviousAppointmentsRecords,
  setSelectedPatient,
} = AppointmentsSlice.actions;

export default AppointmentsSlice.reducer;
