import React, { useState } from "react";
import { IntlProvider } from "react-intl";
import English from "../languages/en-US.json";
import French from "../languages/fr-FR.json";
import Spanish from "../languages/es-ES.json";

export const Context = React.createContext();

console.log(localStorage.getItem("selectedlanguage"));
const local =
  localStorage.getItem("selectedlanguage") !== null
    ? localStorage.getItem("selectedlanguage")
    : "en-US"; // navigator.language;

// Change this based on selected language
//https://www.youtube.com/watch?v=YrWRxs-lqj4

let lang;
if (local === "en-US") {
  lang = English;
} else if (local === "fr-FR") {
  lang = French;
} else {
  lang = Spanish;
}

const Wrapper = (props) => {
  const [locale, setLocale] = useState(local);
  const [messages, setMessages] = useState(lang);

  function selectLang(e) {
    const newLocale = e;
    setLocale(newLocale);
    if (newLocale === "en-US") {
      setMessages(English);
    } else if (newLocale === "fr-FR") {
      setMessages(French);
    } else {
      setMessages(Spanish);
    }
    localStorage.setItem("selectedlanguage", newLocale);
  }
  return (
    <Context.Provider value={{ locale, selectLang }}>
      <IntlProvider messages={messages} locale={locale}>
        {props.children}
      </IntlProvider>
    </Context.Provider>
  );
};

export default Wrapper;
