import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  roleAccessRecords: null,
};

export const RoleManagementSLice = createSlice({
  name: "RoleManagement",
  initialState,
  reducers: {
    getRoleAccessRecords: (state, action) => {
      state.roleAccessRecords = action.payload;
    },
    clearRolesAccess: (state, action) => {
      state.roleAccessRecords = null;
    },
  },
});

export const { getRoleAccessRecords, clearRolesAccess } =
  RoleManagementSLice.actions;

export default RoleManagementSLice.reducer;
